*{
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: blue rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(117, 117, 117, 0.26);
  /* border-radius: 40px; */
  /* border: 3px solid rgb(255, 255, 255); */
}

div {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #333;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  margin: 0;
}

  header{
    display: block;
  }

  .header {
    background: #ffffff;
    border-bottom: 1px solid #ffffff;
    padding-top:1px;
    text-align: center;
    position: sticky;
    top: 0;
    color: rgb(0, 0, 0);
    z-index: 9999;
  }

    /* .header-portfolio-holder{
      background-color: #000000;
      width: 400px;
      border-radius: 0  0 15px 15px;
      color: rgb(255, 255, 255);
      margin: 0 auto;
    }

    .header-portfolio-holder-text{
      margin-top: 0em;
      margin-bottom: 0em;
      padding-top: 20px;
      padding-bottom: 20px;
    } */

  .container{
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
  }

    .page-doc {
      display: flex;
    }

      .page-doc_sidebar{
        background-color: #ffffff;
        flex: 0 0 12em;
      }

        .mgd-sidebar {
          height: 100%;
          padding: 1rem;
          
        }

          .mgd-sidebar__body {
            display: block;
            list-style-type: none;
            margin: 0 0 0 0.25rem;
            padding: 0 0 0 1rem;
            
          }

            .mgd-sidebar__item {
              display: block;
              margin: 0;
              padding: 0.05rem 0;
              text-decoration: none;
            }

              .prof_img{
                height: 205px;
                padding-top: 20px;
              }



              #mgd-sideBaritem-save_button{
                height: 44px;
                padding: 10px;
                font-family: 'Poppins', sans-serif;
                border-radius: 5px;
                background-color: #000000;
                color: #ffffff;
                border: #ffffff;
              }

              #mgd-sideBaritem-save_button:hover{
                box-shadow: 2px 2px 20px 0px #c2c2c2;
              }

      .page-doc_content{
        flex: 1 1;
        background-color: #ffffff;
        overflow-x: hidden;
        padding: 2rem 0 2rem 2rem;
      }
        .page-doc_content-image_list{
          background-color: #ffffff;
          height: 76.4vh;
          max-width: 125vh;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: scroll;
          white-space: nowrap;
          box-shadow:  2px 2px 20px 0px #c2c2c2;
          border: solid #dbdbdb;
          margin-left: 13.5vh;
        }

  .footer {
    background-color: #ffffff;
    padding: 0.5%;
    color: rgb(0, 0, 0);
    text-align: center;
  }
    .GOTOLINK{
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: #ffffff;
      color:#ffffff;
      font-family: 'Poppins', sans-serif;
    }
    
    .GOTOLINK:hover{
      box-shadow: 2px 2px 20px 0px #c2c2c2;
    }

      .GOTOLINK.LinkedIN{
        background-color: #0a66c2;
      }

      .GOTOLINK.Github{
        background-color: #000000;
      }

        .link{
          height: 100%;
          width: 100%;
          color: white;
          text-decoration: none;
        }